import React from "react"
import * as styles from "./index.module.scss"

const Breadcrumbs = ({ title = null }) => {
  if (!title) return null
  return (
    <section className={styles.breadcrumbs_wrapper}>
      <div className="container">
        <h1>{title}</h1>
      </div>
    </section>
  )
}

export default Breadcrumbs
